import { useEffect, useMemo } from 'react';
import { throttle } from 'lodash';

export type ScrollListenerHandler = (event: {
  scrollTop: number;
  documentHeight: number;
  y: number;
  target: { documentElement: HTMLElement };
}) => any;

const useScrollListener = (onScroll: ScrollListenerHandler | undefined, throttleTimeout = 500) => {
  const onScrollThrottled = useMemo(() => {
    if (!onScroll) {
      return () => {};
    }

    return throttle(onScroll, throttleTimeout, {
      leading: true,
    });
  }, [onScroll, throttleTimeout]);

  useEffect(() => {
    // @ts-expect-error
    window?.addEventListener('scroll', onScrollThrottled, {
      passive: true,
    });

    return () => {
      // @ts-expect-error
      window?.removeEventListener('scroll', onScrollThrottled);
    };
  }, [onScrollThrottled]);
};

export default useScrollListener;
